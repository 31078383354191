import React, { useEffect, useState, useRef } from "react";
import "../styles/whyChooseAngSlider.scss";


import one from "../assets/page4/1.png"
import two from "../assets/page4/2.jpeg"
import three from "../assets/page4/3.jpeg"
import four from "../assets/page4/4.jpeg"
import five from "../assets/page4/5.jpeg"
import six from "../assets/page4/6.jpeg"
import seven from "../assets/page4/7.jpeg"


function DubaiOfferingOurOfferingMobileSection() {
  // ---------------------------------------------slider swipe left right---------------------------------------------------------
  const content = [
    {
      image: two,
    
    },
    {
      image: one,
     
    },
    {
      image: three,
    
    },
    {
      image: four,
     
    },
    {
      image: five,
    
    },
    {
      image: six,
     
    },
    {
      image: seven,
    
    },
   
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current?.addEventListener("scroll", function () {
        const scrollPosition = scrollRef.current.scrollLeft;
        const slideWidth = scrollRef.current.offsetWidth;
        const progress = Math.round(scrollPosition / slideWidth);
        console.log(progress);
        if (progress !== currentIndex) {
          setInterval(
            setCurrentIndex((prevIndex) => progress % content.length)
          );
        }
      });
    }
  });

  useEffect(() => {
    if (scrollRef.current) {
      let isDown = false;
      let startX;
      let scrollLeft;

      scrollRef.current.addEventListener("mousedown", (e) => {
        isDown = true;
        scrollRef.current.classList.add("active");
        startX = e.pageX - scrollRef.current.offsetLeft;
        scrollLeft = scrollRef.current.scrollLeft;
      });
      scrollRef.current.addEventListener("mouseleave", (_) => {
        isDown = false;
        scrollRef.current.classList.remove("active");
      });
      scrollRef.current.addEventListener("mouseup", (_) => {
        isDown = false;
        scrollRef.current.classList.remove("active");
      });
      scrollRef.current.addEventListener("mousemove", (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - scrollRef.current.offsetLeft;
        const SCROLL_SPEED = 3;
        const walk = (x - startX) * SCROLL_SPEED;
        scrollRef.current.scrollLeft = scrollLeft - walk;
      });
    }
  }, []);

  // -------------------END------------------

  return (
    <>
      <div className="DubaiOfferingOurOfferingMobile">
        {/* -----------------------------------------slider swipe left right------------------------------------------------- */}

        <div
          className="DubaiOfferingOurOfferingMobileFlexSection"
          ref={scrollRef}
          // style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          <div className="adjustInCenter">
            {content.map((item, index) => (
              <div
                className="DubaiOfferingOurOfferingMobileFirstDiv"
                key={index}
              >
                <div className="DubaiOfferingOurOfferingMobileFirstDivImage">
                  <img
                    src={item.image}
                    alt="OfferingImage"
                    // style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="threedots">
          <div className="carousel-dots">
            {content.map((item, index) => (
              <div
                onClick={() => {
                  setCurrentIndex(index);
                }}
                key={index}
                className={`press-carousel-item ${
                  index === currentIndex ? "active" : ""
                }`}
              ></div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default DubaiOfferingOurOfferingMobileSection;
