import React from "react";
import "../styles/page1.scss";
// import angLogo from "../assets/page1/angLogo.png";
// angUpdatedLogo.png

import angLogo from "../assets/page1/angUpdatedLogo.png";
import ImagePage1 from "../assets/page1/ImagePage1.png";
import Line1 from "../assets/page1/Line1.png";
import videoHero from "../../src/assets/page1/heroSection.mp4";
import videoHero1 from "../../src/assets/page1/bgVideo.mp4"

export default function Page1() {
  return (
    <>
      <div className="page1">
        {/* <div className="upperDrop">
          <img src={angLogo} alt="angLogo" />
        </div> */}
        <div className="topSection">
        <video
  autoPlay
  loop
  muted
  playsInline 
  preload="auto"
  className="video video-wrap topVideo" 
>
  <source src={videoHero1} type="video/mp4" />
  Your browser does not support HTML5 video.
</video>

          <div className="overly"></div>

          <div className="video-content">
            <div className="upperDrop">
              <img src={angLogo} alt="angLogo" />
            </div>

            <div className="middleHead">
              <h1 className="firstMiddleName">BUILD A SUCCESSFUL</h1>
              <h1 className="firstMiddleName">CAREER IN </h1>
              <h1 className="thirdMiddleName">CRYPTO</h1>
            </div>

            <div className="hrLineClass"></div>

            <div className="belowPara">
              <h1>“Unlocking profits with our mentorship program”</h1>
            </div>
          </div>
        </div>

        {/* <div className="bottomShow">
          <div className="innerBottomShow">
            <div className="img">
              <img src={ImagePage1} alt="ImagePage1" />
            </div>
            <div className="lineDrop">
              <img src={Line1} alt="lineLogo" />
            </div>
            <div className="rightText">
              Master Cryptos
              <br />
              with our Online
              <br />
              <span>Learning Course</span>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}
