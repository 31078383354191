import React from "react";
import "../styles/page6.scss";

export default function Page6(props) {
  return (
    <>
      <div className="page6">
        <div className="Header">
          <div className="Heading">
            <div className="rightText">
              <span>
                {" "}
                UPCOMING BATCHES
              </span>
            </div>
          </div>

          <div className="subHeading">
            <span>
            Secure your spot in our future oriented cryptocurrency batches.
            </span>
          </div>
        </div>

        <div className="content">
          <div className="content1">
            <div className="card">
              <div className="headerMain">
                <div className="Heading">OFFLINE</div>

                <div className="subHeading">LIMITED SEATS</div>
              </div>

              <div className="headerSec">
                <div className="Heading">Mon - Thu</div>

                {/* <div className="subHeading">SAT & SUN</div> */}
              </div>
              <div className="headerThr">
                <div className="Heading">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M19.5 4H16.5V2.5C16.5 2.36739 16.4473 2.24021 16.3536 2.14645C16.2598 2.05268 16.1326 2 16 2C15.8674 2 15.7402 2.05268 15.6464 2.14645C15.5527 2.24021 15.5 2.36739 15.5 2.5V4H8.5V2.5C8.5 2.36739 8.44732 2.24021 8.35355 2.14645C8.25979 2.05268 8.13261 2 8 2C7.86739 2 7.74021 2.05268 7.64645 2.14645C7.55268 2.24021 7.5 2.36739 7.5 2.5V4H4.5C3.8372 4.00079 3.20178 4.26444 2.73311 4.73311C2.26444 5.20178 2.00079 5.8372 2 6.5V19.5C2.00079 20.1628 2.26444 20.7982 2.73311 21.2669C3.20178 21.7356 3.8372 21.9992 4.5 22H19.5C20.1629 21.9995 20.7985 21.7359 21.2672 21.2672C21.7359 20.7985 21.9995 20.1629 22 19.5V6.5C21.9995 5.83712 21.7359 5.20154 21.2672 4.73282C20.7985 4.26409 20.1629 4.00053 19.5 4ZM21 19.5C21 19.8978 20.842 20.2794 20.5607 20.5607C20.2794 20.842 19.8978 21 19.5 21H4.5C4.10218 21 3.72064 20.842 3.43934 20.5607C3.15804 20.2794 3 19.8978 3 19.5V11H21V19.5ZM21 10H3V6.5C3 5.672 3.67 5 4.5 5H7.5V6.5C7.5 6.63261 7.55268 6.75979 7.64645 6.85355C7.74021 6.94732 7.86739 7 8 7C8.13261 7 8.25979 6.94732 8.35355 6.85355C8.44732 6.75979 8.5 6.63261 8.5 6.5V5H15.5V6.5C15.5 6.63261 15.5527 6.75979 15.6464 6.85355C15.7402 6.94732 15.8674 7 16 7C16.1326 7 16.2598 6.94732 16.3536 6.85355C16.4473 6.75979 16.5 6.63261 16.5 6.5V5H19.5C19.8978 5 20.2794 5.15804 20.5607 5.43934C20.842 5.72064 21 6.10218 21 6.5V10Z"
                      fill="#303030"
                      fill-opacity="0.45"
                    />
                  </svg>
                  <text>8th JULY</text>
                </div>

                <div className="subHeading">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                  >
                    <path
                      d="M14 3.0625C11.8368 3.0625 9.72212 3.70397 7.92346 4.9058C6.12479 6.10763 4.72291 7.81583 3.89507 9.8144C3.06724 11.813 2.85064 14.0121 3.27267 16.1338C3.69469 18.2555 4.73639 20.2043 6.26602 21.734C7.79566 23.2636 9.74454 24.3053 11.8662 24.7273C13.9879 25.1494 16.187 24.9328 18.1856 24.1049C20.1842 23.2771 21.8924 21.8752 23.0942 20.0765C24.296 18.2779 24.9375 16.1632 24.9375 14C24.9343 11.1002 23.781 8.32003 21.7305 6.26954C19.68 4.21905 16.8998 3.06568 14 3.0625ZM14 24.0625C12.0098 24.0625 10.0643 23.4723 8.40958 22.3667C6.75481 21.261 5.46507 19.6894 4.70347 17.8508C3.94186 16.0121 3.74259 13.9888 4.13085 12.0369C4.51912 10.085 5.47748 8.292 6.88474 6.88474C8.29201 5.47747 10.085 4.51911 12.0369 4.13085C13.9888 3.74258 16.0121 3.94186 17.8508 4.70346C19.6894 5.46507 21.261 6.7548 22.3667 8.40957C23.4723 10.0643 24.0625 12.0098 24.0625 14C24.0596 16.6679 22.9985 19.2256 21.1121 21.1121C19.2256 22.9985 16.6679 24.0596 14 24.0625ZM20.5625 14C20.5625 14.116 20.5164 14.2273 20.4344 14.3094C20.3523 14.3914 20.241 14.4375 20.125 14.4375H14C13.884 14.4375 13.7727 14.3914 13.6906 14.3094C13.6086 14.2273 13.5625 14.116 13.5625 14V7.875C13.5625 7.75897 13.6086 7.64769 13.6906 7.56564C13.7727 7.48359 13.884 7.4375 14 7.4375C14.116 7.4375 14.2273 7.48359 14.3094 7.56564C14.3914 7.64769 14.4375 7.75897 14.4375 7.875V13.5625H20.125C20.241 13.5625 20.3523 13.6086 20.4344 13.6906C20.5164 13.7727 20.5625 13.884 20.5625 14Z"
                      fill="#303030"
                      fill-opacity="0.45"
                    />
                  </svg>
                  <text>1:00 pm</text>
                </div>
              </div>
            </div>

            <div className="buttonDownload" onClick={()=>{
                props.openDialog();
            }} >
                <div className="button">
                    <span>
                    Inquire Now
                    </span>
                </div>

            </div>
          </div>

          <div className="content2">
          <div className="card">
              <div className="headerMain">
                <div className="Heading">ONLINE</div>

                <div className="subHeading">LIMITED SEATS</div>
              </div>

              <div className="headerSec">
                <div className="Heading">MON - THU</div>

                {/* <div className="subHeading">SAT & SUN</div> */}
              </div>
              <div className="headerThr">
                <div className="Heading">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M19.5 4H16.5V2.5C16.5 2.36739 16.4473 2.24021 16.3536 2.14645C16.2598 2.05268 16.1326 2 16 2C15.8674 2 15.7402 2.05268 15.6464 2.14645C15.5527 2.24021 15.5 2.36739 15.5 2.5V4H8.5V2.5C8.5 2.36739 8.44732 2.24021 8.35355 2.14645C8.25979 2.05268 8.13261 2 8 2C7.86739 2 7.74021 2.05268 7.64645 2.14645C7.55268 2.24021 7.5 2.36739 7.5 2.5V4H4.5C3.8372 4.00079 3.20178 4.26444 2.73311 4.73311C2.26444 5.20178 2.00079 5.8372 2 6.5V19.5C2.00079 20.1628 2.26444 20.7982 2.73311 21.2669C3.20178 21.7356 3.8372 21.9992 4.5 22H19.5C20.1629 21.9995 20.7985 21.7359 21.2672 21.2672C21.7359 20.7985 21.9995 20.1629 22 19.5V6.5C21.9995 5.83712 21.7359 5.20154 21.2672 4.73282C20.7985 4.26409 20.1629 4.00053 19.5 4ZM21 19.5C21 19.8978 20.842 20.2794 20.5607 20.5607C20.2794 20.842 19.8978 21 19.5 21H4.5C4.10218 21 3.72064 20.842 3.43934 20.5607C3.15804 20.2794 3 19.8978 3 19.5V11H21V19.5ZM21 10H3V6.5C3 5.672 3.67 5 4.5 5H7.5V6.5C7.5 6.63261 7.55268 6.75979 7.64645 6.85355C7.74021 6.94732 7.86739 7 8 7C8.13261 7 8.25979 6.94732 8.35355 6.85355C8.44732 6.75979 8.5 6.63261 8.5 6.5V5H15.5V6.5C15.5 6.63261 15.5527 6.75979 15.6464 6.85355C15.7402 6.94732 15.8674 7 16 7C16.1326 7 16.2598 6.94732 16.3536 6.85355C16.4473 6.75979 16.5 6.63261 16.5 6.5V5H19.5C19.8978 5 20.2794 5.15804 20.5607 5.43934C20.842 5.72064 21 6.10218 21 6.5V10Z"
                      fill="#303030"
                      fill-opacity="0.45"
                    />
                  </svg>
                  <text>9th JULY</text>
                </div>

                <div className="subHeading">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                  >
                    <path
                      d="M14 3.0625C11.8368 3.0625 9.72212 3.70397 7.92346 4.9058C6.12479 6.10763 4.72291 7.81583 3.89507 9.8144C3.06724 11.813 2.85064 14.0121 3.27267 16.1338C3.69469 18.2555 4.73639 20.2043 6.26602 21.734C7.79566 23.2636 9.74454 24.3053 11.8662 24.7273C13.9879 25.1494 16.187 24.9328 18.1856 24.1049C20.1842 23.2771 21.8924 21.8752 23.0942 20.0765C24.296 18.2779 24.9375 16.1632 24.9375 14C24.9343 11.1002 23.781 8.32003 21.7305 6.26954C19.68 4.21905 16.8998 3.06568 14 3.0625ZM14 24.0625C12.0098 24.0625 10.0643 23.4723 8.40958 22.3667C6.75481 21.261 5.46507 19.6894 4.70347 17.8508C3.94186 16.0121 3.74259 13.9888 4.13085 12.0369C4.51912 10.085 5.47748 8.292 6.88474 6.88474C8.29201 5.47747 10.085 4.51911 12.0369 4.13085C13.9888 3.74258 16.0121 3.94186 17.8508 4.70346C19.6894 5.46507 21.261 6.7548 22.3667 8.40957C23.4723 10.0643 24.0625 12.0098 24.0625 14C24.0596 16.6679 22.9985 19.2256 21.1121 21.1121C19.2256 22.9985 16.6679 24.0596 14 24.0625ZM20.5625 14C20.5625 14.116 20.5164 14.2273 20.4344 14.3094C20.3523 14.3914 20.241 14.4375 20.125 14.4375H14C13.884 14.4375 13.7727 14.3914 13.6906 14.3094C13.6086 14.2273 13.5625 14.116 13.5625 14V7.875C13.5625 7.75897 13.6086 7.64769 13.6906 7.56564C13.7727 7.48359 13.884 7.4375 14 7.4375C14.116 7.4375 14.2273 7.48359 14.3094 7.56564C14.3914 7.64769 14.4375 7.75897 14.4375 7.875V13.5625H20.125C20.241 13.5625 20.3523 13.6086 20.4344 13.6906C20.5164 13.7727 20.5625 13.884 20.5625 14Z"
                      fill="#303030"
                      fill-opacity="0.45"
                    />
                  </svg>
                  <text>10:00 pm</text>
                </div>
              </div>
            </div>

            <div className="buttonDownload" onClick={()=>{
                props.openDialog();
            }} >
                <div className="button">
                    <span>
                    INQUIRE NOW
                    </span>
                </div>

            </div>
          </div>
     
        </div>
      </div>
    </>
  );
}
