import React, { useState } from "react";
import "./Dialog.scss";
import axios from "axios";
import success from "../../assets/whyChooseAngSlider/success.png";

const Dialog = (props) => {
  return (
    <div className="dialog-container">
      {props.isOpen && (
        <div className="dialog">
          <div
            style={{
              width: "367px",
              position: "relative",
              top: "31%",
              display: "flex",
              alignItems: "center",
              textAlign: "center",
              flexDirection: "column",
              justifyContent: "center",
              gap: "24px",
              maxHeight: props.verifyOptState ? "230px" : "140px",
              minHeight: "130px",
              borderRadius: "4px",
              background: "#FFF",
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "column", gap: "12px" }}
            >
              {props.verifyOptState && (
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="64"
                    height="64"
                    viewBox="0 0 64 64"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_690_17613)">
                      <path
                        d="M46.592 22.0162C46.08 21.7602 45.312 21.7602 44.8 22.0162L28.928 38.4002L21.504 30.7202C20.992 30.2082 20.224 30.2082 19.712 30.7202C19.2 31.2322 19.2 32.0002 19.456 32.5122L27.904 40.9602H28.16C28.16 40.9602 28.416 40.9602 28.416 41.2162H29.44C29.44 41.2162 29.696 41.2162 29.696 40.9602H29.952L46.8479 23.8082C46.8479 23.2962 46.848 22.5282 46.592 22.0162Z"
                        fill="#4B7745"
                      />
                      <path
                        d="M32 64C14.336 64 0 49.664 0 32C0 14.336 14.336 0 32 0C49.664 0 64 14.336 64 32C64 49.664 49.664 64 32 64ZM32 2.56C15.872 2.56 2.56 15.872 2.56 32C2.56 48.128 15.872 61.44 32 61.44C48.128 61.44 61.44 48.128 61.44 32C61.44 15.872 48.128 2.56 32 2.56Z"
                        fill="#4B7745"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_690_17613">
                        <rect width="64" height="64" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              )}
              <div
                style={{
                  width: props.verifyOptState ? "90%" : "100%",
                  margin: "auto",
                }}
              >
                <span
                  style={{
                    color: "#606268",
                    textAlign: "center",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "130%",
                  }}
                >
                  {props.verifyOptState
                    ? "We have sent 4 digits verification code to your given mobile."
                    : props.message}
                </span>
              </div>
            </div>

            <div
              className="ok"
              onClick={() => {
                props.openDialog();
              }}
            >
              Ok
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dialog;
