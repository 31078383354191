import "./App.scss";
import whatsapp from "./assets/app/Whatsapp.png";
import React, { useState } from "react";
import Page1 from "./components/Page1";
import Page2 from "./components/Page2";
import Page3 from "./components/Page3";
import Page4 from "./components/Page4";
import Page5 from "./components/Page5";
import Page6 from "./components/Page6";
import Page7 from "./components/Page7";
// import Page8 from "./components/page8";
import Page8 from "./components/Footer.jsx"
import Page9 from "./components/page9";
import Dialog from "./components/Dialog/Dialog";
// import OurExpertUpdated from "../src/components/ourExpertTrainerUpdated.jsx";
import DesktopPage4 from "./components/desktopViewTestimonials.jsx"
import PopUp from "./components/PopUp.jsx"
// --------------------------------------
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import PrivacyPolicy from '../src/components/privacyPolicy/PrivacyPolicy';
import PrivacyPolicy from "../src/components/privacyPolicy/privacyPolicy.jsx" 
import Home from "./page.js"

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const openDialog = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
   
    <Router>
      <Routes> 
        <Route exact path="/" element={<Home />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} /> 
      </Routes>
    </Router>
    </>
  );
}

export default App;
