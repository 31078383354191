import React from "react";
import "../styles/page2.scss";

export default function Page2() {
  return (
    <>
      {/* <div className="page2">
            <div className="videoSection">
                <div className="videoContainer">
                    <div className="video">

                    </div>

                </div>
                <div className="Heading">
                    <div className="rightText">BUILD A SUCCESSFULL <br />

                        <span>   CAREER IN  <text> CRYPTO</text> </span></div>
                </div>

                <div className="subHeading">
                    <span>
                    Acquire the skills needed to forge a successful career in the field of cryptocurrency.  Empower yourself with expert knowledge and opportunities for growth.                     </span>

                </div>

            </div>
        </div> */}
    </>
  );
}
