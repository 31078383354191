import React, { useEffect, useRef, useState } from "react";
import yash from "../assets/expert/yash.png";
import sayyam from "../assets/expert/sayyam.png";
import Vivek from "../assets/page4/Image.jpeg"
import vivek from "../assets/expert/vivek.png";
import "../styles/ourExpertTrainerUpdated.scss";
import { gsap } from "gsap";

import experienceImage from "../assets/expert/experience.png";
import certificationImage from "../assets/expert/certification.png";
import expertiseImage from "../assets/expert/experience.png";

function OurExpertTrainer() {
  const [activeDot, setActiveDot] = useState(0);

  let currentSlider = useRef(0);
  let intervalStorage;

  useEffect(() => {
    startAnimation();
    return () => {
      stopAnimation();
    };
  }, []);

  // ----------------- for Swipe ------------------------

  const [startX, setStartX] = useState(0); // X-coordinate where the swipe started
  const [distanceX, setDistanceX] = useState(0); // Distance swiped

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setDistanceX(e.touches[0].clientX - startX);
  };

  const handleTouchEnd = () => {
    if (distanceX > 100) {
      // Change this to the minimum distance you want for a swipe
      leftAnimation();
    } else if (distanceX < -100) {
      // Change this to the minimum distance you want for a swipe
      rightAnimation();
    }

    // Reset values
    setStartX(0);
    setDistanceX(0);
  };

  // --------------------------------------------

  const startAnimation = () => {
    if (!intervalStorage) {
      intervalStorage = setInterval(() => {
        currentSlider.current = currentSlider.current + 1;
        if (currentSlider.current >= 3) {
          currentSlider.current = 0;
        }
        setActiveDot(currentSlider.current);
        performAnimation();
      }, 5000);
    }
  };

  const stopAnimation = () => {
    clearInterval(intervalStorage);
    intervalStorage = undefined;
  };

  const leftAnimation = () => {
    // stopAnimation();
    currentSlider.current = currentSlider.current - 1;
    if (currentSlider.current === -1) {
      currentSlider.current = 2;
    }
    setActiveDot(currentSlider.current);
    performAnimation();
    setTimeout(() => {
      // startAnimation();
    }, 1000);
  };

  const rightAnimation = () => {
    // stopAnimation();
    currentSlider.current = currentSlider.current + 1;
    if (currentSlider.current >= 3) {
      currentSlider.current = 0;
    }
    setActiveDot(currentSlider.current); // Add this line
    performAnimation();
    setTimeout(() => {
      // startAnimation();
    }, 1000);
  };

  const performAnimation = () => {
    gsap.to(
      ".ourExpertTrainerMain .ourExpertTrainerMainContainer .ourExpertTrainerBorderRadius",
      {
        x: "-" + 100 * currentSlider.current + "vw",
        ease: "power2.out",
        duration: 1,
      }
    );
  };

  return (
    <div
      className="ourExpertTrainerMain"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div className="ourExpertTrainerMainContainer">
        <div
          className="demoCheckColor"
          // onTouchStart={handleTouchStart}
          // onTouchMove={handleTouchMove}
          // onTouchEnd={handleTouchEnd}
        >
          <div className="ourExpertTrainerBorderRadius">
            <div className="adjustSlider">
              <div className="firstSection">
                <div className="firstSectionImage">
                  <img src={yash} alt="yash" />
                  <div className="overLayText">
                    <div className="nameClass">Yash Gupta</div>
                    <div className="designationClass">
                    Lead Mentor & Director
                    </div>
                  </div>
                </div>

                <div className="belowSection">
                  <div className="belowSectionBorder">
                    <div className="belowSectionDiv">
                      <div className="belowSectionImage">
                        <img src={experienceImage} alt="experienceImage" />
                      </div>

                      <h1 className="belowHead">Experience </h1>
                      <hr className="belowHr" />

                      <h1 className="belowYears">10+ yrs</h1>
                    </div>

                    <div className="belowSectionDiv">
                      <div className="belowSectionImage">
                        <img src={experienceImage} alt="experienceImage" />
                      </div>

                      <h1 className="belowHead">Certification </h1>
                      <hr className="belowHr" />

                      <h1 className="belowYears">14 +</h1>
                    </div>

                    <div className="belowSectionDiv">
                      <div className="belowSectionImage">
                        <img src={experienceImage} alt="experienceImage" />
                      </div>

                      <h1 className="belowHead">Expertise</h1>
                      <hr className="belowHr" />

                      <h1 className="belowYears">Crypto, Stocks</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="demoCheckColor"
          // onTouchStart={handleTouchStart}
          // onTouchMove={handleTouchMove}
          // onTouchEnd={handleTouchEnd}
        >
          <div className="ourExpertTrainerBorderRadius">
            <div className="adjustSlider">
              <div className="firstSection">
                <div className="firstSectionImage">
                  <img src={sayyam} alt="sayyam" />

                  <div className="overLayText">
                    <div className="nameClass">Sayyam Agarwal</div>
                    <div className="designationClass">
                    Mentor
                    </div>
                  </div>
                </div>

                <div className="belowSection">
                  <div className="belowSectionBorder">
                    <div className="belowSectionDiv">
                      <div className="belowSectionImage">
                        <img src={experienceImage} alt="experienceImage" />
                      </div>

                      <h1 className="belowHead">Experience </h1>

                      <hr className="belowHr" />

                      <h1 className="belowYears">8+ yrs</h1>
                    </div>

                    <div className="belowSectionDiv">
                      <div className="belowSectionImage">
                        <img src={experienceImage} alt="experienceImage" />
                      </div>

                      <h1 className="belowHead">Certification </h1>
                      <hr className="belowHr" />

                      <h1 className="belowYears">7 +</h1>
                    </div>

                    <div className="belowSectionDiv">
                      <div className="belowSectionImage">
                        <img src={experienceImage} alt="experienceImage" />
                      </div>

                      <h1 className="belowHead">Expertise</h1>
                      <hr className="belowHr" />

                      <h1 className="belowYears">Stocks, Crypto</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="demoCheckColor"
          // onTouchStart={handleTouchStart}
          // onTouchMove={handleTouchMove}
          // onTouchEnd={handleTouchEnd}
        >
          <div className="ourExpertTrainerBorderRadius">
            <div className="adjustSlider">
              <div className="firstSection">
                <div className="firstSectionImage">
                  <img src={Vivek} alt="vivek" />

                  <div className="overLayText">
                    <div className="nameClass">Vivek</div>
                    <div className="designationClass">
                    Mentor
                    </div>
                  </div>
                </div>

                <div className="belowSection">
                  <div className="belowSectionBorder">
                    <div className="belowSectionDiv">
                      <div className="belowSectionImage">
                        <img src={experienceImage} alt="experienceImage" />
                      </div>

                      <h1 className="belowHead">Experience </h1>
                      <hr className="belowHr"/>

                      <h1 className="belowYears">6+ yrs</h1>
                    </div>

                    <div className="belowSectionDiv">
                      <div className="belowSectionImage">
                        <img src={experienceImage} alt="experienceImage" />
                      </div>

                      <h1 className="belowHead">Certification </h1>
                      <hr className="belowHr" />

                      <h1 className="belowYears">Self taught</h1>
                    </div>

                    <div className="belowSectionDiv">
                      <div className="belowSectionImage">
                        <img src={experienceImage} alt="experienceImage" />
                      </div>

                      <h1 className="belowHead">Expertise</h1>
                      <hr className="belowHr" />

                      <h1 className="belowYears">Crypto, Forex</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="progressBar">
        {/* ----------------------------------------- Left Swipe ---------------------- */}
        {/* <div
          className="leftSlide"
          style={{ fontSize: "10vw" }}
          onClick={leftAnimation}
        >
          {" "}
          >{" "}
        </div> */}

        <div className="dots">
          <span className={`dot ${activeDot === 0 ? "active" : ""}`}></span>
          <span className={`dot ${activeDot === 1 ? "active" : ""}`}></span>
          <span className={`dot ${activeDot === 2 ? "active" : ""}`}></span>
        </div>

        {/* ----------------------------------------- Right Swipe ---------------------- */}

        {/* <div
          className="rightSlide"
          style={{ fontSize: "10vw" }}
          onClick={rightAnimation}
        >
          {" "}
          >{" "}
        </div> */}
      </div>
    </div>
  );
}

export default OurExpertTrainer;
