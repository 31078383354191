import React from "react";
import "../styles/desktop.scss";

import yash from "../assets/expert/yash.png";
import sayyam from "../assets/expert/sayyam.png";
import vivek from "../assets/expert/vivek.png";
import experienceImage from "../assets/expert/experience.png";
import Vivek from "../assets/page4/Image.jpeg"
export default function Page7() {
  return (
    <>
      <div className="pagedesktop7">
        <div className="Header">
          <div className="Heading">
            <div className="rightText">
              
              <span> Our EXPERT TRAINER </span>
            
            </div>
          </div>

          <div className="subHeading">
          <span>
            Powering your crypto journey with insights and guidance from our exceptional team of expert trainers.
            </span>
          </div>
        </div>

        <div className="ourExpertTrainerMain1">
          <div className="ourExpertTrainerMainContainer1">
            <div className="demoCheckColor">
              <div className="ourExpertTrainerBorderRadius">
                <div className="adjustSlider">
                  <div className="firstSection">
                    <div className="firstSectionImage">
                      <img src={yash} alt="yash" />
                      <div className="overLayText">
                        <div className="nameClass">Yash Gupta</div>
                        <div className="designationClass">
                          Lead Mentor & Director
                        </div>
                      </div>
                    </div>

                    <div className="belowSection">
                      <div className="belowSectionBorder">
                        <div className="belowSectionDiv">
                          <div className="belowSectionImage">
                            <img src={experienceImage} alt="experienceImage" />
                          </div>

                          <h1 className="belowHead">Experience </h1>
                          <hr className="belowHr" />

                          <h1 className="belowYears">10+ yrs</h1>
                        </div>

                        <div className="belowSectionDiv">
                          <div className="belowSectionImage">
                            <img src={experienceImage} alt="experienceImage" />
                          </div>

                          <h1 className="belowHead">Certification </h1>
                          <hr className="belowHr" />

                          <h1 className="belowYears">14 +</h1>
                        </div>

                        <div className="belowSectionDiv">
                          <div className="belowSectionImage">
                            <img src={experienceImage} alt="experienceImage" />
                          </div>

                          <h1 className="belowHead">Expertise</h1>
                          <hr className="belowHr" />

                          <h1 className="belowYears">Crypto, Stocks</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="demoCheckColor"
              // onTouchStart={handleTouchStart}
              // onTouchMove={handleTouchMove}
              // onTouchEnd={handleTouchEnd}
            >
              <div className="ourExpertTrainerBorderRadius">
                <div className="adjustSlider">
                  <div className="firstSection">
                    <div className="firstSectionImage">
                      <img src={sayyam} alt="sayyam" />

                      <div className="overLayText">
                        <div className="nameClass">Sayyam Agarwal</div>
                        <div className="designationClass">Mentor</div>
                      </div>
                    </div>

                    <div className="belowSection">
                      <div className="belowSectionBorder">
                        <div className="belowSectionDiv">
                          <div className="belowSectionImage">
                            <img src={experienceImage} alt="experienceImage" />
                          </div>

                          <h1 className="belowHead">Experience </h1>

                          <hr className="belowHr" />

                          <h1 className="belowYears">8+ yrs</h1>
                        </div>

                        <div className="belowSectionDiv">
                          <div className="belowSectionImage">
                            <img src={experienceImage} alt="experienceImage" />
                          </div>

                          <h1 className="belowHead">Certification </h1>
                          <hr className="belowHr" />

                          <h1 className="belowYears">7 +</h1>
                        </div>

                        <div className="belowSectionDiv">
                          <div className="belowSectionImage">
                            <img src={experienceImage} alt="experienceImage" />
                          </div>

                          <h1 className="belowHead">Expertise</h1>
                          <hr className="belowHr" />

                          <h1 className="belowYears">Stocks, Crypto</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="demoCheckColor"
              // onTouchStart={handleTouchStart}
              // onTouchMove={handleTouchMove}
              // onTouchEnd={handleTouchEnd}
            >
              <div className="ourExpertTrainerBorderRadius">
                <div className="adjustSlider">
                  <div className="firstSection">
                    <div className="firstSectionImage">
                      <img src={Vivek} alt="vivek" />

                      <div className="overLayText">
                        <div className="nameClass">Vivek</div>
                        <div className="designationClass">Mentor</div>
                      </div>
                    </div>

                    <div className="belowSection">
                      <div className="belowSectionBorder">
                        <div className="belowSectionDiv">
                          <div className="belowSectionImage">
                            <img src={experienceImage} alt="experienceImage" />
                          </div>

                          <h1 className="belowHead">Experience </h1>
                          <hr className="belowHr" />

                          <h1 className="belowYears">6+ yrs</h1>
                        </div>

                        <div className="belowSectionDiv">
                          <div className="belowSectionImage">
                            <img src={experienceImage} alt="experienceImage" />
                          </div>

                          <h1 className="belowHead">Certification </h1>
                          <hr className="belowHr" />

                          <h1 className="belowYears">Self taught</h1>
                        </div>

                        <div className="belowSectionDiv">
                          <div className="belowSectionImage">
                            <img src={experienceImage} alt="experienceImage" />
                          </div>

                          <h1 className="belowHead">Expertise</h1>
                          <hr className="belowHr" />

                          <h1 className="belowYears">Crypto, Forex</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
