import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import footerLogoImage from "../assets/page1/angUpdatedLogo.png";
import Fb from "../assets/app/facebook.png";
import insta from "../assets/app/insta.png";



import "../styles/footer.scss";
const FooterMobile = () => {

  const navigate = useNavigate();

  const handleClick = () => {
    // Navigate to the "/PrivacyPolicy" route
    navigate('/PrivacyPolicy');
  };

  // const handleLinkClick = () => {
  //   window.open('/PrivacyPolicy', '_blank'); // Opens the '/other-component' route in a new tab
  // };
  return (
    <>
      <div className="footerMain">
        <div className="footerMainContainer">
          <div className="leftSideFooter">
            <li href={`/`} style={{ textDecoration: "none" }}>
              <div className="footerLogo" style={{ cursor: "pointer" }}>
                <img src={footerLogoImage} alt="footerLogo" />
              </div>
            </li>

            <div className="allSocialLink">
              <div className="socialInline">
                <a
                  className="socialAnchor"
                  href="https://www.instagram.com/ang.traders?igsh=bzF3cWZmc256azdn "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      color: "transparent",
                      width: "22px",
                      height: "22px",
                      margin: "auto",
                      padding: "16px",
                    }}
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="#ffffff"
                      d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                    />
                  </svg>
                </a>
              </div>
              <div className="socialInline">
                <a
                  className="socialAnchor"
                  href="https://www.threads.net/@ang.traders"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      color: "transparent",
                      width: "22px",
                      height: "22px",
                      margin: "auto",
                      padding: "16px",
                    }}
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="#ffffff"
                      d="M331.5 235.7c2.2 .9 4.2 1.9 6.3 2.8c29.2 14.1 50.6 35.2 61.8 61.4c15.7 36.5 17.2 95.8-30.3 143.2c-36.2 36.2-80.3 52.5-142.6 53h-.3c-70.2-.5-124.1-24.1-160.4-70.2c-32.3-41-48.9-98.1-49.5-169.6V256v-.2C17 184.3 33.6 127.2 65.9 86.2C102.2 40.1 156.2 16.5 226.4 16h.3c70.3 .5 124.9 24 162.3 69.9c18.4 22.7 32 50 40.6 81.7l-40.4 10.8c-7.1-25.8-17.8-47.8-32.2-65.4c-29.2-35.8-73-54.2-130.5-54.6c-57 .5-100.1 18.8-128.2 54.4C72.1 146.1 58.5 194.3 58 256c.5 61.7 14.1 109.9 40.3 143.3c28 35.6 71.2 53.9 128.2 54.4c51.4-.4 85.4-12.6 113.7-40.9c32.3-32.2 31.7-71.8 21.4-95.9c-6.1-14.2-17.1-26-31.9-34.9c-3.7 26.9-11.8 48.3-24.7 64.8c-17.1 21.8-41.4 33.6-72.7 35.3c-23.6 1.3-46.3-4.4-63.9-16c-20.8-13.8-33-34.8-34.3-59.3c-2.5-48.3 35.7-83 95.2-86.4c21.1-1.2 40.9-.3 59.2 2.8c-2.4-14.8-7.3-26.6-14.6-35.2c-10-11.7-25.6-17.7-46.2-17.8H227c-16.6 0-39 4.6-53.3 26.3l-34.4-23.6c19.2-29.1 50.3-45.1 87.8-45.1h.8c62.6 .4 99.9 39.5 103.7 107.7l-.2 .2zm-156 68.8c1.3 25.1 28.4 36.8 54.6 35.3c25.6-1.4 54.6-11.4 59.5-73.2c-13.2-2.9-27.8-4.4-43.4-4.4c-4.8 0-9.6 .1-14.4 .4c-42.9 2.4-57.2 23.2-56.2 41.8l-.1 .1z"
                    />
                  </svg>
                </a>
              </div>
              <div className="socialInline">
                <a
                  className="socialAnchor"
                  href="https://ig.me/j/AbZC3NdoeoV2ydkH/"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"  style={{
                      color: "transparent",
                      width: "22px",
                      height: "22px",
                      margin: "auto",
                      padding: "16px",
                    }}>
                    <path  fill="#ffffff" d="M194.4 211.7a53.3 53.3 0 1 0 59.3 88.7 53.3 53.3 0 1 0 -59.3-88.7zm142.3-68.4c-5.2-5.2-11.5-9.3-18.4-12c-18.1-7.1-57.6-6.8-83.1-6.5c-4.1 0-7.9 .1-11.2 .1c-3.3 0-7.2 0-11.4-.1c-25.5-.3-64.8-.7-82.9 6.5c-6.9 2.7-13.1 6.8-18.4 12s-9.3 11.5-12 18.4c-7.1 18.1-6.7 57.7-6.5 83.2c0 4.1 .1 7.9 .1 11.1s0 7-.1 11.1c-.2 25.5-.6 65.1 6.5 83.2c2.7 6.9 6.8 13.1 12 18.4s11.5 9.3 18.4 12c18.1 7.1 57.6 6.8 83.1 6.5c4.1 0 7.9-.1 11.2-.1c3.3 0 7.2 0 11.4 .1c25.5 .3 64.8 .7 82.9-6.5c6.9-2.7 13.1-6.8 18.4-12s9.3-11.5 12-18.4c7.2-18 6.8-57.4 6.5-83c0-4.2-.1-8.1-.1-11.4s0-7.1 .1-11.4c.3-25.5 .7-64.9-6.5-83l0 0c-2.7-6.9-6.8-13.1-12-18.4zm-67.1 44.5A82 82 0 1 1 178.4 324.2a82 82 0 1 1 91.1-136.4zm29.2-1.3c-3.1-2.1-5.6-5.1-7.1-8.6s-1.8-7.3-1.1-11.1s2.6-7.1 5.2-9.8s6.1-4.5 9.8-5.2s7.6-.4 11.1 1.1s6.5 3.9 8.6 7s3.2 6.8 3.2 10.6c0 2.5-.5 5-1.4 7.3s-2.4 4.4-4.1 6.2s-3.9 3.2-6.2 4.2s-4.8 1.5-7.3 1.5l0 0c-3.8 0-7.5-1.1-10.6-3.2zM448 96c0-35.3-28.7-64-64-64H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96zM357 389c-18.7 18.7-41.4 24.6-67 25.9c-26.4 1.5-105.6 1.5-132 0c-25.6-1.3-48.3-7.2-67-25.9s-24.6-41.4-25.8-67c-1.5-26.4-1.5-105.6 0-132c1.3-25.6 7.1-48.3 25.8-67s41.5-24.6 67-25.8c26.4-1.5 105.6-1.5 132 0c25.6 1.3 48.3 7.1 67 25.8s24.6 41.4 25.8 67c1.5 26.3 1.5 105.4 0 131.9c-1.3 25.6-7.1 48.3-25.8 67z" />
                  </svg>
                </a>
              </div>
              <div className="socialInline">
                <a
                  className="socialAnchor"
                  href="https://youtube.com/@iamyashgupta?feature=shared"
                >
                  <svg
                    style={{
                      color: "transparent",
                      width: "22px",
                      height: "22px",
                      margin: "auto",
                      padding: "16px",
                    }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                  >
                    <path
                      fill="#ffffff"
                      d="M549.7 124.1c-6.3-23.7-24.8-42.3-48.3-48.6C458.8 64 288 64 288 64S117.2 64 74.6 75.5c-23.5 6.3-42 24.9-48.3 48.6-11.4 42.9-11.4 132.3-11.4 132.3s0 89.4 11.4 132.3c6.3 23.7 24.8 41.5 48.3 47.8C117.2 448 288 448 288 448s170.8 0 213.4-11.5c23.5-6.3 42-24.2 48.3-47.8 11.4-42.9 11.4-132.3 11.4-132.3s0-89.4-11.4-132.3zm-317.5 213.5V175.2l142.7 81.2-142.7 81.2z"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>

          <div className="content">
            <span>Our Location</span>

            <text>
              8th Floor, A - Tower,Picasso Center, Sector61, Golf Course
              Extension Road, Gurgaon
            </text>
          </div>
          <div className=" leftArea">
            <div className="pinkBox">
              <div className="massagePinkBox">
                looking to be part of our community?
                <br />
                <b>
                  <a href="mailto:admissions@aspirenowglobal.com">
                    admissions@aspirenowglobal.com
                  </a>
                </b>
                <br />
                <span style={{
                  textDecoration: "underline",
                  textDecorationskip: "ink",
                  textUnderlineOffset: "0.2em",
                  cursor:"pointer"
                }}onClick={handleClick}>Privacy Policy</span>
                 {/* <a href="#" 
                 style={{
                  textDecoration: "underline",
                  textDecorationskip: "ink",
                  textUnderlineOffset: "0.2em",
                }}
                 onClick={handleLinkClick}>Privacy Policy</a> */}
                 {/* <span style={{
                  textDecoration: "underline",
                  textDecorationskip: "ink",
                  textUnderlineOffset: "0.2em",
                  cursor:"pointer"
                }}>Privacy Policy</span> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FooterMobile;
